.dialog {
    padding: 1em;
    box-shadow: 0 0 5px rgba(101, 110, 119, 0.2);
    border: 0;
    margin: 0 auto;
    border-radius: 8px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1031;
    display: flex;
    justify-content: center;
    align-items: center;

    .dialog-container {
        background: white;
        width: 50%;
        height: auto;
        border-radius: 10px;
        .dialog-content {
            padding: 16px;
        }
        .close-btn {
            cursor: pointer;
            position: absolute;
            background: #9c9a9ab3;
            top: 0px;
            right: 0;
            width: 20px;
            height: 20px;
            i.fa.fa-close {
                color: #666;
                font-size: 13px;
                margin: 0px 0 0px 5px;
            }
        }
    }
}

.dialog[open] {
    animation: 0.2s linear slidein;
}

.dialog::backdrop {
    background: rgba(224, 228, 232, 0.9);
    animation: 0.4s ease fade;
}

@keyframes slidein {
    from {
        transform: translate3d(0, 5px, 0);
    }
    to {
        transfrom: translate3d(0, 0, 0);
    }
}

@keyframes fade {
    from {
        opacity: 0.3;
    }
    to {
        opacity: 1;
    }
}
