.access-denied-container{
    display: flex;
    background: rgb(255, 255, 255);
    flex-direction: column;
    align-items: center;
    padding-top: 12%;
    height: 100%;
    img {
      align-self: center;
    }
}
